
        <template>
          <div class="vc-snippet-doc">
            <h2>数据库代码规范</h2>
<h3>数据库规范</h3>
<p>1.主键id字段必须自增</p>
<p>2.禁用text相关类型字段，使用varchar代替</p>
<p>3.数据库deleted逻辑删除时值为当前记录id值</p>
<p>4.数据库字段建表时必须添加注释</p>
<p>5.经常变化字段数据禁止冗余，比如：创建人名称(created_by_name) 修改人名称(updated_by_name)，需要用到此字段值，通过业务代码接口查询</p>
<p>6.不同模块禁止使用联表查询</p>
<p>7.建立索引时按照以下格式建立 <code>idx_字段1_字段2</code></p>
<p>8.使用模糊搜索时禁止前缀模糊 如：%xxxx</p>
<p>9.数据表默认字段要求 所有数据表均以以下5个字段结尾（若业务无修改需求，updated_by字段updated_time字段可省略）：</p>
<pre class="hljs"><code> `deleted` int(9) NOT NULL DEFAULT 0,		--是否删除默认为0  （未删除为0；已删除为当前数据的id）
 `created_by` int(9) NOT NULL DEFAULT 0,				--创建者
 `created_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP,		--创建时间默认为当前时间
 `updated_by` int(9) DEFAULT 0,			--更新者	
 `updated_time` timestamp NULL DEFAULT CURRENT_TIMESTAMP,	--修改时间
</code></pre>
<p>10.数据库字段必须增加默认值，不允许为NULL</p>
<h3>代码接口规范</h3>
<p>1.编写接口时增加swagger文档说明 如:</p>
<pre class="hljs"><code><span class="hljs-meta">@Api</span>(tags = { <span class="hljs-string">"模块说明"</span> }) <span class="hljs-comment">// controller类上增加说明</span>

<span class="hljs-meta">@ApiOperation</span>(value = <span class="hljs-string">"保存h5权限"</span>, notes = <span class="hljs-string">"保存h5权限"</span>)
<span class="hljs-meta">@ApiImplicitParams</span>({
    <span class="hljs-meta">@ApiImplicitParam</span>(name = <span class="hljs-string">"checkKeys"</span>, value = <span class="hljs-string">"勾选后的json数据"</span>, required = <span class="hljs-keyword">true</span>, dataType = <span class="hljs-string">"String"</span>),
    <span class="hljs-meta">@ApiImplicitParam</span>(name = <span class="hljs-string">"type"</span>, value = <span class="hljs-string">"1内部 2外部"</span>, required = <span class="hljs-keyword">true</span>, dataType = <span class="hljs-string">"String"</span>),
})
</code></pre>
<p>2.接口参数返回说明，返回参数必须加注释说明</p>
<p>3.顶层RequestMapping必须是 <code>/固定前缀(/ajax/badu)/一级模块/二级模块</code>，三级模块自定   如：</p>
<pre class="hljs"><code><span class="hljs-comment">// /ajax/badu 固定前缀</span>
<span class="hljs-comment">// ranch -- 安欣模块 一级模块</span>
<span class="hljs-comment">// manager -- 安心模块下面 manager 二级模块</span>
<span class="hljs-meta">@Controller</span>
<span class="hljs-meta">@RequestMapping</span>(<span class="hljs-string">"/ajax/badu/ranch/manager"</span>)
<span class="hljs-keyword">public</span> <span class="hljs-class"><span class="hljs-keyword">class</span> <span class="hljs-title">RanchBaseSettingController</span> <span class="hljs-keyword">extends</span> <span class="hljs-title">BaseController</span> </span>{
   
    <span class="hljs-comment">// 三级模块</span>
    <span class="hljs-meta">@RequestMapping</span>(value = <span class="hljs-string">"/addRanchKind.do"</span>, method = RequestMethod.POST)
    <span class="hljs-meta">@ResponseBody</span>
    <span class="hljs-function"><span class="hljs-keyword">public</span> JsonResult <span class="hljs-title">addRanchKind</span><span class="hljs-params">(HttpServletRequest request, String name)</span> </span>{
       <span class="hljs-keyword">return</span> JsonResult.returnSuccessObj(<span class="hljs-string">"新增生物种类成功"</span>);
   	 }
 }
</code></pre>
<p>4.日志打印统一使用slf4j 下的Logger 如：</p>
<pre class="hljs"><code>
<span class="hljs-keyword">public</span> <span class="hljs-class"><span class="hljs-keyword">class</span> <span class="hljs-title">RanchBaseSettingController</span> <span class="hljs-keyword">extends</span> <span class="hljs-title">BaseController</span> </span>{
    <span class="hljs-keyword">private</span> <span class="hljs-keyword">static</span> <span class="hljs-keyword">final</span> Logger logger = LoggerFactory.getLogger(RanchBaseSettingController<span class="hljs-class">.<span class="hljs-keyword">class</span>)</span>;
}
</code></pre>
<p>5.代码方法增加注释说明，用处。禁止修改他人代码，需要别人提供方法找相关人员为你提供  注释格式：</p>
<pre class="hljs"><code><span class="hljs-comment">/**
 * <span class="hljs-doctag">@author</span> 张三
 * <span class="hljs-doctag">@date</span>  2020/7/8 17:10
 * <span class="hljs-doctag">@desc</span>  新增牧场种类
 **/</span>
<span class="hljs-function"><span class="hljs-keyword">void</span> <span class="hljs-title">addRanchKind</span><span class="hljs-params">(RanchKind ranchKind)</span> <span class="hljs-keyword">throws</span> BdSaasException</span>;
</code></pre>
<p>说明：idea工具可以通过idea自带模板工具设定格式</p>
<p>6.非必须使用时，禁止在for循环内出现操作数据库语句</p>
<p>7.代码存放位置及命名规则</p>
<p><strong>controller:</strong></p>
<p>​	存放位置 :  src\main\java\com\badu\btsaas\controller\</p>
<p>​	例： src\main\java\com\badu\btsaas\controller\ ranch\RanchDailyFeedController</p>
<p><strong>service:</strong></p>
<p>​	接口存放位置： src\main\java\com\badu\bdsaas\baseinfo\service</p>
<p>​	例： src\main\java\com\badu\bdsaas\baseinfo\service\RanchDailyFeedService</p>
<p>​	实现存放位置：src\main\java\com\badu\bdsaas\baseinfo\service\impl</p>
<pre><code>例： src\main\java\com\badu\bdsaas\baseinfo\service\impl\RanchDailyFeedServiceImpl
</code></pre>
<p><strong>dao:</strong></p>
<p>​	mapper存放位置：src\main\java\com\badu\bdsaas\baseinfo\dao\</p>
<p>​	例： src\main\java\com\badu\bdsaas\baseinfo\dao\RanchDailyFeedMapper</p>
<p>​	xml存放位置: src\main\resources\com\badu\bdsaas\baseinfo\</p>
<p>​	例： src\main\resources\com\badu\bdsaas\baseinfo\RanchDailyFeedMapper</p>

          </div>
        </template>
        <script>
           
           export default {
           name: 'vc-component-doc',
           components: {
            
           }
         }
       </script>
       <style lang='scss'>
         
       </style>